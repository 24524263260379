import React from 'react'
import useGoBack from '../../../hooks/useGoBack.hook'

const BackButton = () => {

    const { goBack } = useGoBack()

    return (

        <button onClick={goBack} className="h-5 w-5 bg-[#2ebefa] flex items-center justify-center rounded-full cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="text-white h-3.5 w-3.5">
                <path strokeLinecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        </button>

    )
}

export default BackButton