import React, { useContext, useEffect, useState } from "react";
import EventContext from "../../context/events/eventContext";
import storage from "../../helpers/storage";
import ResourceContext from "../../context/resource/resourceContext";
import body from "../../helpers/body";
import UserContext from "../../context/users/userContext";
import moment from "moment";
import BackButton from "./partials/BackButton";

const TopBar = ({ header, text, show }) => {
  const eventContext = useContext(EventContext);
  const resourceContext = useContext(ResourceContext);
  const userContext = useContext(UserContext);

  useEffect(() => { }, [eventContext?.sessionEvent]);

  const selectEvent = (val) => {
    const session = eventContext.sessions.find((s) => s?.event?.title === val);
    eventContext.setSessionEvent(session?.event?._id);
    eventContext.setSessionEventTitle(session?.event?.title);
    eventContext.getParticipants(session?.event?._id, 10, 1);
    eventContext.getResourceGroups(session?.event?._id, 20, 1);
    eventContext.getResourceGroupTypes(session?.event?._id, 20, 1);
    eventContext.getAccomodations(session?.event?._id, 10, 1);
    eventContext.setSpecialAccomodation(session?.event?._id);
    eventContext.setRegularAccomodation(session?.event?._id);
    eventContext.setSessionId(session?._id);
  };

  return (
    <div className="pl-[300px] fixed right-0 left-0 top-0 h-[65px] border-b border-gray-200 bg-[#d6f2ff]/40 flex items-center z-10">

      <div className="flex items-center justify-center">
        <div className="flex items-center gap-2">
          <BackButton />
          <p className="text-[#096cb6] text-md font-semibold">{header}</p>
        </div>
      </div>

      {(storage.fetchLegacy("sb-menu") === "participants" ||
        storage.fetchLegacy("sb-menu") === "resource group" ||
        storage.fetchLegacy("sb-menu") === "accomodation") &&
        storage.getUserRole() === 'superadmin' && (
          <div className="form-group flex  items-center pr-12 ml-auto">
            <span className="pr-6 text-sm text-[#096cb6]  font-medium">Showing Records for</span>

            <div className="">
              <select
                id="session"
                name="session"
                autoComplete="session"
                className="w-full rounded-md border-gray-400 font-medium border py-1 px-3 text-sm text-[#2c5392] outline-none shadow-sm  sm:max-w-xs sm:leading-6 cursor-pointer"
                onChange={(e) => selectEvent(e.target.value)}
              >
                {eventContext.sessions.map((s, index) => (
                  // <option
                  //   key={s._id}
                  //   value={s?.event?.title}
                  //   selected={index === 0 ? true : false}
                  // >
                  //   {s?.event?.title}
                  // </option>
                  <option
                    key={s._id}
                    value={s?.event?.title}
                    selected={s?.event?.isCurrent === true ? true : false}
                  >
                    {s?.event?.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

      {(storage.fetchLegacy("sb-menu") === "participants" ||
        storage.fetchLegacy("sb-menu") === "resource group" ||
        storage.fetchLegacy("sb-menu") === "accomodation") &&
        storage.getUserRole() !== "superadmin" && (
          <div className="form-group flex flex-col self-end mb-1 pr-12 ml-auto">
            <div className="mt-1">
              <select
                id="session"
                name="session"
                autoComplete="session"
                className="w-full rounded-md border-gray-400 font-medium border py-1 px-3 text-sm text-[#2c5392] outline-none shadow-sm  sm:max-w-xs sm:leading-6 cursor-pointer"
                defaultChecked={eventContext.userSession?.event?.title}
              >
                <option
                  value={eventContext.userSession?.event?.title}
                  selected={true}
                >
                  {eventContext.userSession?.event?.title}
                </option>
              </select>
            </div>
          </div>
        )}
    </div>
  );
};

export default TopBar;
