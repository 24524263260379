export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'
export const SEARCH_USERS = 'SEARCH_USERS'

export const SEARCH_PARTICIPANTS = 'SEARCH_PARTICIPANTS'
export const GET_PARTICIPANTS = 'GET_PARTICIPANTS'
export const GET_PARTICIPANT = 'GET_PARTICIPANT'

export const GET_RESOURCES_GROUPS = 'GET_RESOURCES_GROUPS'
export const GET_RESOURCE_GROUP_TYPES = 'GET_RESOURCE_GROUP_TYPES'
export const GET_RESOURCE_TYPE_GROUPS = 'GET_RESOURCE_TYPE_GROUPS'
export const GET_EVENT_RESOURCES_GROUPS = 'GET_EVENT_RESOURCES_GROUPS'

export const GET_SERVICE_POINTS = 'GET_SERVICE_POINTS'

export const GET_CATEGORY_TYPES = 'GET_CATEGORY_TYPES'
export const GET_POSSIBLE_CATEGORY_TYPES = 'GET_POSSIBLE_CATEGORY_TYPES'
export const GET_POSSIBLE_CATEGORY_VALUES = 'GET_POSSIBLE_CATEGORY_VALUES'

export const GET_ACCOMODATIONS = 'GET_ACCOMODATIONS'
export const GET_ACCOMODATION = 'GET_ACCOMODATION'
export const SET_REGULAR_ACCOMODATION = 'SET_REGULAR_ACCOMODATION'
export const SET_SPECIAL_ACCOMODATION = 'SET_SPECIAL_ACCOMODATION'
export const SET_ACCOMODATION_ID = 'SET_ACCOMODATION_ID'

export const GET_ROOMS = 'GET_ROOMS'
export const GET_ROOMS_BY_ID = 'GET_ROOMS_BY_ID'
export const GET_ROOM = 'GET_ROOM'
export const GET_ROOM_TYPE_VALUES = 'GET_ROOM_TYPE_VALUES'

export const GET_FEEDBACKS = 'GET_FEEDBACKS'

export const CHECKEDIN = 'CHECKEDIN'
export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_STATS = 'GET_EVENT_STATS'
export const SEARCH_EVENTS = 'SEARCH_EVENTS'

export const GET_SESSIONS = 'GET_SESSIONS'
export const GET_STATS = 'GET_STATS'
export const GET_USER_SESSIONS = 'GET_USER_SESSIONS'
export const SET_SESSION_EVENT = 'SET_SESSION_EVENT'
export const GET_SESSION_EVENT = 'GET_SESSION_EVENT'
export const SET_SESSION_EVENT_TITLE = 'SET_SESSION_EVENT_TITLE'
export const SHOW_SELECT = 'SHOW_SELECT'
export const SET_SESSION_ID = 'SET_SESSION_ID'

export const SET_SIDEBAR = 'SET_SIDEBAR'
export const SET_USER_EMAILS = 'SET_USER_EMAILS'

export const GET_CENTERS = 'GET_CENTERS'
export const SEARCH = 'SEARCH'

export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_EVENT_PAGINATION = 'SET_EVENT_PAGINATION'
export const SET_ACCOMODATION_PAGINATION = 'SET_ACCOMODATION_PAGINATION'
export const SET_ROOMS_PAGINATION = 'SET_ROOMS_PAGINATION'
export const SET_PARTICIPANT_PAGINATION = 'SET_PARTICIPANT_PAGINATION'
export const SET_RESOURCE_GROUP_TYPE_PAGINATION = 'SET_RESOURCE_GROUP_PAGINATION'
export const SET_RESOURCE_TYPE_GROUPS_PAGINATION = 'SET_RESOURCE_TYPE_GROUPS_PAGINATION'
export const SET_RESOURCE_GROUP_PAGINATION = 'SET_RESOURCE_GROUP_PAGINATION'
export const SET_RESOURCE_GROUPS_VALUES = 'SET_RESOURCE_GROUPS_VALUES'


export const SET_LOADING = 'SET_LOADING'