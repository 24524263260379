import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import {
  CHECKEDIN,
  GET_ACCOMODATIONS,
  GET_ACCOMODATION_ID,
  GET_EVENTS,
  GET_PARTICIPANTS,
  GET_PARTICIPANT,
  GET_ROOMS,
  GET_ROOMS_BY_ID,
  GET_SERVICE_POINTS,
  GET_SESSIONS,
  GET_USER_SESSIONS,
  SEARCH_PARTICIPANTS,
  SET_ACCOMODATION_ID,
  SET_SESSION_EVENT,
  SET_LOADING,
  SET_PAGINATION,
  SET_SESSION_ID,
  GET_STATS,
  GET_RESOURCES_GROUPS,
  GET_EVENT_RESOURCES_GROUPS,
  SET_SESSION_EVENT_TITLE,
  SET_SPECIAL_ACCOMODATION,
  SET_REGULAR_ACCOMODATION,
  SET_EVENT_PAGINATION,
  SET_PARTICIPANT_PAGINATION,
  GET_CENTERS,
  SET_RESOURCE_GROUP_PAGINATION,
  SET_ACCOMODATION_PAGINATION,
  GET_ACCOMODATION,
  GET_EVENT,
  SEARCH,
  GET_RESOURCE_GROUP_TYPES,
  SET_RESOURCE_GROUP_TYPE_PAGINATION,
  SET_RESOURCE_TYPE_GROUPS_PAGINATION,
  GET_RESOURCE_TYPE_GROUPS,
  SET_RoOMS_PAGINATION,
  SET_ROOMS_PAGINATION,
  GET_ROOM,
  SET_RESOURCE_GROUPS_VALUES,
  GET_EVENT_STATS,
  SET_USER_EMAILS,
  GET_SESSION_EVENT,
  GET_ROOM_TYPE_VALUES,
} from "../types";
import Axios from "axios";
import EventContext from "./eventContext";
import EventReducer from "./eventReducer";
import storage from "../../helpers/storage";

const EventState = (props) => {
  const navigate = useNavigate();

  const initialState = {
    events: [],
    event: {},
    eventStats: {},
    userEmails: [],
    sessionEvent: "",
    sessionEventTitle: "",
    // sessionEventUpdate: {},
    sessionId: "",
    sessions: [],
    userSession: {},
    participants: [],
    participant: {},
    checkedIn: [],
    search: [],
    accomodations: [],
    accomodation: {},
    accomodationId: "",
    rooms: [],
    room: {},
    roomTypeValues: [],
    regularAccomodation: {},
    specialAccomodation: {},
    accomodationRooms: [],
    resourceGroupTypes: [],
    resourceTypeGroups: [],
    resourceGroups: [],
    resourceGroupValues: [],
    eventResourceGroups: [],
    centers: [],
    stats: {},
    pagination: {},
    eventPagination: {},
    participantPagination: {},
    rgPagination: {},
    rTypePagination: {},
    rTypeGroupPagination: {},
    accPagination: {},
    roomsPagination: {},
    loading: false,
  };

  const [state, dispatch] = useReducer(EventReducer, initialState);

  const logout = async () => {
    storage.clearAuth();
    navigate("/");
  };

  const filterKeys = (obj, keysToRemove) => {
    if (Array.isArray(obj)) {
      return obj?.map((item) => filterKeys(item, keysToRemove));
    } else if (typeof obj === "object" && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) => !keysToRemove.includes(key)
        ).map(([key, value]) => [key, filterKeys(value, keysToRemove)])
      );
    }
    return obj;
  };

  const filteredData = (array) => {
    const filterData = array.map(({ _id, available, ...rest }) => rest);
    return filterData
  }

  const consolidateData = (array) => {
    const result = {
      "Age Group": [],
      "Occupation Group": [],
      "Marital Status": [],
      Gender: [],
    };

    array.forEach((item) => {
      // Add unique age groups
      if (item.ageGroup) {
        result["Age Group"] = [
          ...new Set([...result["Age Group"], ...item.ageGroup]),
        ];
      }

      // Add unique occupation groups
      if (item.occupationGroup) {
        result["Occupation Group"] = [
          ...new Set([...result["Occupation Group"], ...item.occupationGroup]),
        ];
      }

      // Add unique marital statuses
      if (item.maritalStatus) {
        result["Marital Status"] = [
          ...new Set([...result["Marital Status"], ...item.maritalStatus]),
        ];
      }

      // Add unique genders
      if (item.gender) {
        result["Gender"] = [
          ...new Set([...result["Gender"], ...item.gender]),
        ];
      }
    });

    return result;
  };

  const getCenters = async () => {
    setLoading();
    try {
      const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/centers`, storage.getConfigWithBearer());

      dispatch({
        type: GET_CENTERS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getEvents = async (limit, page) => {
    const q = `limit=${limit}&page=${page}`;

    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/events?${q}`, storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_EVENT_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_EVENTS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getEvent = async (id) => {

    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/${id}`
      );

      dispatch({
        type: GET_EVENT,
        payload: resp.data.data,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getEventStats = async (id) => {

    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/participants/stats/${id}`, storage.getConfigWithBearer()
      );

      dispatch({
        type: GET_EVENT_STATS,
        payload: resp.data.data,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };


  const searchEvent = async (limit, page, keyword) => {
    const q = `limit=${limit}&page=${page}&keyword=${keyword}`;
    try {
      setLoading()
      const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events?${q}`, storage.getConfigWithBearer())

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages
      }

      dispatch({
        type: SET_PAGINATION,
        payload: paginate
      })

      dispatch({
        type: SEARCH,
        payload: resp.data.data.docs
      })
    } catch (error) {

      console.log(error)
    }
  }

  const setSessionId = (val) => {
    dispatch({
      type: SET_SESSION_ID,
      payload: val,
    });
  };

  const setSessionEvent = (val) => {
    setLoading();
    dispatch({
      type: SET_SESSION_EVENT,
      payload: val,
    });
  };

  // const getSessionEvent = () => {
  //   setLoading();
  //   dispatch({
  //     type: GET_SESSION_EVENT,
  //     payload: { eventId: state.sessionEvent, eventTitle: state.sessionEventTitle },
  //   });
  // };

  const setSessionEventTitle = (val) => {
    setLoading();
    dispatch({
      type: SET_SESSION_EVENT_TITLE,
      payload: val,
    });
  };

  const getParticipants = async (eventId, limit=30, page=1) => {
    const populate = `_populate[]=resourceGroups&_populate[]=room&_populate[]=servicePoint&_populate[]=accomodation`
    const q = `event=${eventId}&_limit=${limit}&_page=${page}&${populate}`;
    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/participants?${q}`,
        storage.getConfigWithBearer()
      );
      let data = [];
      const checkedIn = resp.data.data.docs.filter((c) => {
        if (c.checkedIn === false) {
          data.push(c);
        }
      });

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_PARTICIPANT_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: CHECKEDIN,
        payload: data,
      });
      dispatch({
        type: GET_PARTICIPANTS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getParticipant = async (participantId) => {

    const populate = `_populate=event&_populate[]=resourceGroups&_populate[]=room&_populate[]=servicePoint&_populate[]=accomodation`

    const q = `${populate}`;

    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/participants/${participantId}?${q}`,
        storage.getConfigWithBearer()
      );
    
      dispatch({
        type: GET_PARTICIPANT,
        payload: resp.data.data,
      });

    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const searchParticipants = async (eventId, limit=30, page=1, keyword) => {
    const populate = `_populate[]=resourceGroups&_populate[]=servicePoint&_populate[]=room&_populate[]=accomodation`
    const q = `_searchBy=%5B%22email%22%2C%22firstName%22%2C%22lastName%22%5D&_keyword=${keyword}&event=${eventId}&${populate}&limit=${limit}&page=${page}`;
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/participants?${q}`,
        storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: SEARCH,
        payload: resp.data.data.docs,
      });
    } catch (error) { }
  };

  const clearSearch = async () => {
    dispatch({
      type: SEARCH,
      payload: [],
    });
  };

  const getAccomodations = async (eventId, limit, page) => {
    const q = `event=${eventId}&_limit=${limit}&_page=${page}`;
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/accommodation?${q}`,
        storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_ACCOMODATION_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_ACCOMODATIONS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.resp && error.resp.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const setRegularAccomodation = async (eventId) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/misc/accommodation-stats/${eventId}`,
        storage.getToken()
      );

      dispatch({
        type: SET_REGULAR_ACCOMODATION,
        payload: resp.data.data.regular,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setSpecialAccomodation = async (eventId) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/misc/accommodation-stats/${eventId}`,
        storage.getToken()
      );

      dispatch({
        type: SET_SPECIAL_ACCOMODATION,
        payload: resp.data.data.special,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAccomodation = async (id) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/accommodation/${id}`,
        storage.getConfigWithBearer()
      );

      dispatch({
        type: GET_ACCOMODATION,
        payload: resp.data.data,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getRooms = async (limit, page) => {

    const q = `limit=${limit}&page=${page}`;

    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/room?${q}`,
        storage.getConfigWithBearer()
      );


      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_ROOMS_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_ROOMS,
        payload: resp.data.data.docs
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getRoomsByAccomodation = async (accomodationId, limit, page) => {

    const q = `accomodation=${accomodationId}&_limit=${limit}&_page=${page}`;

    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/room?${q}`, storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: GET_ROOMS_BY_ID,
        payload: resp.data.data.docs,
      });

      dispatch({
        type: SET_ROOMS_PAGINATION,
        payload: paginate,
      });

    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getRoom = async (roomId) => {

    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/room/${roomId}`, storage.getConfigWithBearer()
      );
      const typeValues = [resp.data.data]
      console.log('type', typeValues)
      const keysToExclude = [
        "_id",
        "createdBy",
        "residesInVenueTown",
        "title",
        "availableSlots",
        "totalSlots",
        "available",
        "event",
        "reserved",
        "accomodation",
        "createdAt",
        "updatedAt",
        "__v"
      ]
      const filtered = filteredData(typeValues, keysToExclude);
      console.log('filtered', filtered)
      const consolidate = consolidateData(filtered)
      console.log('consolidate', consolidate)
      dispatch({
        type: GET_ROOM,
        payload: resp.data.data,
      });

      dispatch({
        type: GET_ROOM_TYPE_VALUES,
        payload: consolidate,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getSessions = async (limit, page) => {
    const q = `limit=${limit}&page=${page}`;
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/sessions?status=active&${q}`,
        storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_SESSIONS,
        payload: resp.data.data.docs,
      });

      setSessionId(resp.data.data.docs[0]._id);
      setSessionEvent(resp.data.data.docs[0].event._id);
      setSessionEventTitle(resp.data.data.docs[0].event.title);
      setSpecialAccomodation(resp.data.data.docs[0].event._id);
      setRegularAccomodation(resp.data.data.docs[0].event._id);
      getParticipants(resp.data.data.docs[0].event._id);
      getAccomodations(resp.data.data.docs[0].event._id);
      getResourceGroups(resp.data.data.docs[0].event._id);
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getUserSession = async () => {
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/sessions`,
        storage.getConfigWithBearer()
      );
      dispatch({
        type: GET_USER_SESSIONS,
        payload: resp.data.data.docs[0],
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getAccomodationId = (val) => {
    dispatch({
      type: SET_ACCOMODATION_ID,
      payload: val,
    });
  };

  const getResourceGroups = async (eventId, limit = 10, page = 1) => {
    const q = `limit=${limit}&page=${page}&_populate=type&_populate=event`;
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/resourceGroup?${q}`, storage.getConfigWithBearer()
      );


      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_RESOURCE_GROUP_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_RESOURCES_GROUPS,
        payload: resp.data.data.docs,
      });

    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getResourceTypeGroups = async (resourceTypeId) => {
    const q = `type=${resourceTypeId}`;
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/resourceGroup?${q}`, storage.getConfigWithBearer()
      );

      const keysToExclude = ["_id", "createdBy", "title", "event", "type", "count", "reserved", "createdAt", "updatedAt"]; // Add other keys to exclude here
      const filtered = filterKeys(resp.data.data.docs, keysToExclude);
      const consolidate = consolidateData(filtered)

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_RESOURCE_TYPE_GROUPS_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_RESOURCE_TYPE_GROUPS,
        payload: resp.data.data.docs,
      });

      dispatch({
        type: SET_RESOURCE_GROUPS_VALUES,
        payload: consolidate,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getResourceGroupTypes = async (eventId, limit, page) => {

    const q = `event=${eventId}&limit=${limit}&page=${page}`;

    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/resourceGroupType?${q}&_populate=createdBy`, storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_RESOURCE_GROUP_TYPE_PAGINATION,
        payload: paginate,
      });


      dispatch({
        type: GET_RESOURCE_GROUP_TYPES,
        payload: resp.data.data.docs,
      });

    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getEventResourceGroups = async (eventId) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/resource-groups?event=${eventId}`
      );
      dispatch({
        type: GET_EVENT_RESOURCES_GROUPS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getStats = async () => {
    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/misc/dashboard-stats`
      );
      dispatch({
        type: GET_STATS,
        payload: resp.data.data,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      } else if (error && error.response) {
        console.log(error.response);
      }
    }
  };

  const setUserEmails = (data) => {

    let result = []

    const emails = data?.forEach((x) => {
      return result.push(x?.email)
    })

    console.log(emails)

    dispatch({
      type: SET_USER_EMAILS,
      payload: result
    })

  }

  const setLoading = () => {
    dispatch({
      type: SET_LOADING,
    });
  };

  return (
    <EventContext.Provider
      value={{
        events: state.events,
        event: state.event,
        eventStats: state.eventStats,
        centers: state.centers,
        sessionEvent: state.sessionEvent,
        // sessionEventUpdate: state.sessionEventUpdate,
        sessionEventTitle: state.sessionEventTitle,
        sessionId: state.sessionId,
        sessions: state.sessions,
        userEmails: state.userEmails,
        userSession: state.userSession,
        participants: state.participants,
        participant: state.participant,
        checkedIn: state.checkedIn,
        pagination: state.pagination,
        eventPagination: state.eventPagination,
        participantPagination: state.participantPagination,
        rTypePagination: state.rTypePagination,
        rTypeGroupPagination: state.rTypeGroupPagination,
        rgPagination: state.rgPagination,
        accPagination: state.accPagination,
        roomsPagination: state.roomsPagination,
        search: state.search,
        accomodations: state.accomodations,
        accomodation: state.accomodation,
        regularAccomodation: state.regularAccomodation,
        specialAccomodation: state.specialAccomodation,
        rooms: state.rooms,
        room: state.room,
        roomTypeValues: state.roomTypeValues,
        accomodationRooms: state.accomodationRooms,
        accomodationId: state.accomodationId,
        resourceGroupTypes: state.resourceGroupTypes,
        resourceTypeGroups: state.resourceTypeGroups,
        resourceGroups: state.resourceGroups,
        resourceGroupValues: state.resourceGroupValues,
        eventResourceGroups: state.eventResourceGroups,
        stats: state.stats,
        loading: state.loading,
        getEvents,
        getEvent,
        getEventStats,
        searchEvent,
        getCenters,
        setSessionEvent,
        // getSessionEvent,
        setSessionEventTitle,
        getSessions,
        setSessionId,
        getUserSession,
        getParticipants,
        getParticipant,
        searchParticipants,
        // setPagination,
        clearSearch,
        getAccomodations,
        getAccomodation,
        setRegularAccomodation,
        setSpecialAccomodation,
        getRooms,
        getRoom,
        getRoomsByAccomodation,
        getAccomodationId,
        getEventResourceGroups,
        getResourceGroupTypes,
        getResourceTypeGroups,
        getResourceGroups,
        getStats,
        setUserEmails
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventState;
